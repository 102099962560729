import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { history } from "&store/store";
import { getCurrentUser } from "&config/getCurrentUser";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { RootState } from "&store/store";
import { homeActions } from "./home.slice";
import { loginActions } from "&features/login/login.slice";
import { reportsActions } from "&features/reports/reports.slice";
import CarriersBigIcon from "&assets/icons/carriers-big";
import MerchantsBigIcon from "&assets/icons/merchants-big";
import ServicesBigIcon from "&assets/icons/services-big";
import SubscriptionsBigIcon from "&assets/icons/subscriptions-big";
import DatePickerComponent from "&styled/form/date-picker";
import { SubmitButton } from "&styled/button/button.component";
import SelectComponent from "&styled/form/select";
import CustomChart from "./purchaseChart";
import SummaryCard from "./summaryCards";
import { PieChartComponent } from "&styled/pieChart/pieChart.component";

type ReduxProps = ConnectedProps<typeof connector>;

type SelectFormatted = {
  label: string;
  value: string;
};

const messagesMapping = {
  "INVALID ORDER ID": "System Error",
  "Please provide valid value for TxnRefNo": "System Error",
  "JazzCash Mobile Account does not exist against the provided MSISDN.": "Account Doesn’t Exist",
  'A confirmer sends the short message "N" to cancel a transaction.': "User Cancelled The Transaction",
  "The state tag cooldown of the transaction debit party restricts the fund transfer-out and the transaction fails.":
    "Fund Transfer Restriction On This Wallet",
  "Issue in Easypaisa API": "System Error",
  "Failed to match a reason type because the Initiator Type factor does not match.": "System Error",
  "Oops, something went wrong. We think either your wallet does not exist or your cnic did not match. Please check with our helpline for details.":
    "Account Doesn’t Exist",
  "Failed to authenticate the transaction by limit rule Daily Maximum Total Debit.": "Daily Limit Exceeds",
  "The PIN of the Provided JazzCash Account is currently pending to change or the Provided JazzCash Account is currently in dormant state.":
    "Account Is Dormant",
  "Status updated with inquire API": "Either Success Or Failed (Properly Match The Error)",
  "Failed with 0001": "System Error",
  "Failed to authenticate the transaction by limit rule Debit lock limit rule.": "Daily Limit Exceeds",
  "The balance in account of provided JazzCash Account is insufficient for the transaction.": "Insufficient Balance",
  "Online Gateway Transaction is not allowed on the provided JazzCash Account, Please contact helpline to resolve the issue.":
    "Transaction Is Restricted On This Wallet",
  "Failed with 0014": "System Error",
  "Failed with Transaction rolled back; nested exception is: javax.transaction.TransactionRolledbackException: Transaction is ended due to timeout":
    "System Error",
  "The account balance is frequently updated. The account number is 500000000133185636.": "Account Frequently Updated",
  "Failed to authenticate the transaction by limit rule Monthly Maximum Total Debit.": "Monthly Debit Limit Exceeds",
};

const HomeComponent = (props: ReduxProps) => {
  const {
    isLoggedIn,
    token,
    state,
    filterDashboardSummary,
    getDashboardSummary,
    filterSalesSummary,
    getDashboardSummaryCount,
    getDashboardBilling,
    getMerchants,
  } = props;
  const user = getCurrentUser(token);

  const [serviceDate, setServiceDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [revenueDate, setRevenueDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [billing, setBilling] = useState({
    startDate: new Date(),
    endDate: new Date(),
    serviceName: null,
    merchantName: null,
    status: null,
    operator: null,
    group: "status",
  });
  const [billingDetails, setBillingDetails] = useState({
    startDate: new Date(),
    endDate: new Date(),
    serviceName: null,
    merchantName: null,
    status: "Failed",
    operator: null,
    group: "message",
  });
  const { clients, oneTimePurchase, services, operators, users, roles } = state.summary.cardsSummary;
  const { subscriptionSummary, salesSummary, billingStatus, billingMessages } = state.summary;
  const handleStartDate = (platform: "service" | "revenue", val) => {
    if (platform === "service") {
      setServiceDate((prev) => ({
        ...prev,
        startDate: val,
      }));
    } else {
      setRevenueDate((prev) => ({
        ...prev,
        startDate: val,
      }));
    }
  };

  const handleEndDate = (platform: "service" | "revenue", val) => {
    if (platform === "service") {
      setServiceDate((prev) => ({
        ...prev,
        endDate: val,
      }));
    } else {
      setRevenueDate((prev) => ({
        ...prev,
        endDate: val,
      }));
    }
  };

  const onFilter = async () => {
    await filterDashboardSummary(serviceDate);
  };
  const onSalesFilter = async () => {
    await filterSalesSummary(revenueDate);
  };

  const isWaleeUser = () => {
    return user!.userTypeId === "Walee";
  };

  const totalBilling = billingStatus?.reduce((acc, item) => {
    if (["Success", "Failed"].includes(item._id)) {
      return acc + item.count;
    }
    return acc;
  }, 0);
  useEffect(() => {
    if (!isLoggedIn) {
      history.push("/login");
    }
    getDashboardSummaryCount({
      platform: "subscriptions",
    })
      .then()
      .catch();
    getDashboardSummaryCount({
      platform: "services",
    })
      .then()
      .catch();
    getDashboardSummaryCount({
      platform: "oneTimePurchase",
    })
      .then()
      .catch();
    getDashboardBilling(billing).then().catch();
    getDashboardBilling(billingDetails);
    if (isWaleeUser()) {
      getDashboardSummaryCount({
        platform: "clients",
      })
        .then()
        .catch();
      getDashboardSummaryCount({
        platform: "operators",
      })
        .then()
        .catch();
      getMerchants().then().catch();
    } else {
      getDashboardSummaryCount({
        platform: "roles",
      })
        .then()
        .catch();
      getDashboardSummaryCount({
        platform: "users",
      })
        .then()
        .catch();
    }
    getDashboardSummary().then().catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      height={"80vh"}
      overflow={"auto"}
      sx={{
        scrollbarWidth: "thin",
        scrollbarColor: "#6631F7 #f1f1f1",
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#f1f1f1",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#6631F7",
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#551DF1",
        },
        scrollBehavior: "smooth",
      }}
    >
      <Grid container spacing={1}>
        <Grid item lg={3} md={6} xs={12}>
          <SummaryCard
            label={isWaleeUser() ? "Carriers" : "Users"}
            value={isWaleeUser() ? operators : users}
            icon={CarriersBigIcon}
          />
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          <SummaryCard label={"One-Time Purchase"} value={oneTimePurchase} icon={SubscriptionsBigIcon} />
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          <SummaryCard
            label={isWaleeUser() ? "Merchants" : "Roles"}
            value={isWaleeUser() ? clients : roles}
            icon={MerchantsBigIcon}
          />
        </Grid>
        <Grid item lg={3} md={6} xs={12}>
          <SummaryCard label={"Services"} value={services} icon={ServicesBigIcon} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {/* Service Share */}
        <Grid item lg={6} xs={12}>
          <Card
            sx={{
              width: "100%",
              marginY: "2rem",
              minHeight: "75vh",
              borderRadius: "8px 8px 0px 0px",
              borderTop: "4px solid #6631F7",
            }}
          >
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box sx={{ fontSize: "1.2rem", fontWeight: "bold" }}> Services Share </Box>
              </Box>
              {/*Search filters*/}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  marginY: "1.5rem",
                }}
              >
                <DatePickerComponent
                  label="Date From"
                  value={serviceDate?.startDate}
                  onChange={(val) => {
                    handleStartDate("service", val);
                  }}
                  size="small"
                />
                <DatePickerComponent
                  label="Date To"
                  value={serviceDate?.endDate}
                  onChange={(val) => {
                    handleEndDate("service", val);
                  }}
                  size="small"
                />
                <SubmitButton title="Filter" handlePress={onFilter} />
              </Box>
              <Grid container spacing={2} marginY={2}>
                <Grid item lg={12} xs={12}>
                  <Box>
                    <CustomChart data={subscriptionSummary} />
                  </Box>
                  {/* One Time Purchase table */}
                  <Box>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Service</TableCell>
                          <TableCell>Transactions</TableCell>
                          <TableCell>Service Share (%)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {subscriptionSummary.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.service}
                            </TableCell>
                            <TableCell>{row.count}</TableCell>
                            <TableCell>{row.percentage?.toFixed(2)} %</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/* Revenue Share */}
        <Grid item lg={6} xs={12}>
          <Card
            sx={{
              width: "100%",
              marginY: "2rem",
              minHeight: "75vh",
              borderRadius: "8px 8px 0px 0px",
              borderTop: "4px solid #6631F7",
            }}
          >
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box sx={{ fontSize: "1.2rem", fontWeight: "bold" }}> Revenue Share </Box>
              </Box>
              {/*Search filters*/}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  marginY: "1.5rem",
                }}
              >
                <DatePickerComponent
                  label="Date From"
                  size="small"
                  value={revenueDate?.startDate}
                  onChange={(val) => {
                    handleStartDate("revenue", val);
                  }}
                />
                <DatePickerComponent
                  label="Date To"
                  size="small"
                  value={revenueDate?.endDate}
                  onChange={(val) => {
                    handleEndDate("revenue", val);
                  }}
                />
                <SubmitButton title="Filter" handlePress={onSalesFilter} />
              </Box>
              <Grid container spacing={2} marginY={2}>
                <Grid item lg={12} xs={12}>
                  <Box>
                    <CustomChart data={salesSummary} />
                  </Box>
                  {/*Sales Table*/}

                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Service</TableCell>
                        <TableCell>Transactions</TableCell>
                        <TableCell>{user!.userTypeId === "Walee" ? "Amount" : "Merchant Amount"}</TableCell>
                        <TableCell>Currency</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {salesSummary.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.service}
                          </TableCell>
                          <TableCell>{row.count}</TableCell>
                          <TableCell>{row.amount}</TableCell>
                          <TableCell>{row.currency}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/* Overall Billing Summary Status */}
        <Grid item lg={6} xs={12}>
          <Card
            sx={{
              width: "100%",
              marginY: "1rem",
              minHeight: "75vh",
              borderRadius: "8px 8px 0px 0px",
              borderTop: "4px solid #6631F7",
            }}
          >
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>Billing Summary (Today Status)</Box>
              </Box>
              {/*Search filters*/}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  marginY: "1.5rem",
                }}
              >
                {isWaleeUser() && (
                  <Box>
                    <SelectComponent
                      onSelect={(e) => {
                        if (e.target.value === "Select Merchant") {
                          setBilling({ ...billing, merchantName: null });
                        } else {
                          setBilling({ ...billing, merchantName: e.target.value });
                        }
                      }}
                      value={billing?.merchantName ?? "Select Merchant"}
                      size="small"
                      menuItems={[
                        ...state?.merchants?.map((item) => ({
                          label: item.name,
                          value: item.name,
                        })),
                        {
                          label: "Select Merchant",
                          value: "Select Merchant",
                        },
                      ]}
                    />
                  </Box>
                )}
                <Box>
                  <SelectComponent
                    onSelect={(e) => {
                      if (e.target.value === "Select Operator") {
                        setBilling({ ...billing, operator: null });
                      } else {
                        setBilling({ ...billing, operator: e.target.value });
                      }
                    }}
                    value={billing?.operator ?? "Select Operator"}
                    size="small"
                    menuItems={[
                      {
                        label: "EASYPAISA",
                        value: "EASYPAISA",
                      },
                      {
                        label: "JAZZCASH",
                        value: "JAZZCASH",
                      },
                      {
                        label: "Select Operator",
                        value: "Select Operator",
                      },
                    ]}
                  />
                </Box>
                <SubmitButton
                  title="Filter"
                  handlePress={() => {
                    getDashboardBilling(billing).then().catch();
                  }}
                />
              </Box>
              <Grid container spacing={2} marginY={2}>
                <Grid item lg={12} xs={12}>
                  <Box>
                    <PieChartComponent
                      data={billingStatus?.map((item) => ({
                        name: item._id,
                        value: item.count,
                      }))}
                      showTitle={false}
                    />
                  </Box>
                  <Box height="140px" overflow="auto">
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Status</TableCell>
                          <TableCell>Percentage</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Success
                          </TableCell>
                          <TableCell>
                            {(
                              ((billingStatus?.find((item) => item._id === "Success")?.count ?? 0) / totalBilling) *
                              100
                            ).toFixed(2)}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Failed
                          </TableCell>
                          <TableCell>
                            {(
                              ((billingStatus?.find((item) => item._id === "Failed")?.count ?? 0) / totalBilling) *
                              100
                            ).toFixed(2)}
                            %
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/* Overall Billing Summary Messages */}
        <Grid item lg={6} xs={12}>
          <Card
            sx={{
              width: "100%",
              marginY: "1rem",
              minHeight: "75vh",
              borderRadius: "8px 8px 0px 0px",
              borderTop: "4px solid #6631F7",
            }}
          >
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>Billing Summary (Today’s Failed Logs)</Box>
              </Box>
              {/*Search filters*/}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  marginY: "1.5rem",
                }}
              >
                {isWaleeUser() && (
                  <Box>
                    <SelectComponent
                      onSelect={(e) => {
                        if (e.target.value === "Select Merchant") {
                          setBillingDetails({ ...billingDetails, merchantName: null });
                        } else {
                          setBillingDetails({ ...billingDetails, merchantName: e.target.value });
                        }
                      }}
                      value={billingDetails?.merchantName ?? "Select Merchant"}
                      size="small"
                      menuItems={[
                        ...state?.merchants?.map((item) => ({
                          label: item.name,
                          value: item.name,
                        })),
                        {
                          label: "Select Merchant",
                          value: "Select Merchant",
                        },
                      ]}
                    />
                  </Box>
                )}
                <Box>
                  <SelectComponent
                    onSelect={(e) => {
                      if (e.target.value === "Select Operator") {
                        setBillingDetails({ ...billingDetails, operator: null });
                      } else {
                        setBillingDetails({ ...billingDetails, operator: e.target.value });
                      }
                    }}
                    value={billingDetails?.operator ?? "Select Operator"}
                    size="small"
                    menuItems={[
                      {
                        label: "EASYPAISA",
                        value: "EASYPAISA",
                      },
                      {
                        label: "JAZZCASH",
                        value: "JAZZCASH",
                      },
                      {
                        label: "Select Operator",
                        value: "Select Operator",
                      },
                    ]}
                  />
                </Box>
                <SubmitButton
                  title="Filter"
                  handlePress={() => {
                    getDashboardBilling(billingDetails).then().catch();
                  }}
                />
              </Box>
              <Grid container spacing={2} marginY={2}>
                <Grid item lg={12} xs={12}>
                  <Box>
                    <CustomChart data={billingMessages} />
                  </Box>
                  <Box height="250px" overflow="auto">
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Message</TableCell>
                          <TableCell>Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {billingMessages.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row?.service === "The account balance is frequently updated."
                                ? "Account Frequently Updated"
                                : messagesMapping?.[row?.service] ?? row?.service}
                            </TableCell>
                            <TableCell>{row.count}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

/**
 * Maps state variables from redux store to props of correct component
 * @param state
 */

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: state.login.isLoggedIn,
  token: state.login.token,
  state: state.home,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  setType: reportsActions.setType,
  logout: loginActions.reset,
  getDashboardSummary: homeActions.getDashboardSummary,
  filterDashboardSummary: homeActions.filterDashboardSummary,
  filterSalesSummary: homeActions.filterSalesSummary,
  getDashboardSummaryCount: homeActions.getDashboardSummaryCount,
  getDashboardBilling: homeActions.getDashboardBilling,
  getMerchants: homeActions.getMerchants,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const HomeComponentRedux = connector(HomeComponent);

export { HomeComponentRedux as HomeComponent };
