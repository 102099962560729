import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "34px",
  p: 2,
};

export default function DetailModal({ open, handleClose, values }) {




  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="confirmation-modal"
        aria-describedby="confirmation-modal"
      >
        <Box sx={style}>
          <Box
            sx={{
              marginY: "1rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{ color: "#2e2e2e", fontSize: "20px", fontWeight: "bold" }}
            >
              Services
            </Box>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
          </Box>
          <Divider />
          <Grid container spacing={2} marginY={2}>
            {
              values?.map((item)=>{
                return(
                  <Grid item xs={12} key={item}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0.5rem 1rem",
                        backgroundColor: "#f9f9f9",
                        borderRadius: "8px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#333",
                        }}
                      >
                        {item || "Unnamed Service"}
                      </Box>
                    </Box>
                  </Grid>
                )
              })
            }
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
